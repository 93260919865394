import React from "react"

import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Template({data}) {
	const {markdownRemark: post} = data;

	return (
		<Layout>
    	<SEO title={post.frontmatter.title} keywords={post.frontmatter.keywords} />
		<div>
		<h1>{post.frontmatter.title}</h1>
			<div dangerouslySetInnerHTML={{__html: post.html}} />
		</div>
		</Layout>
	)
}

export const postQuery = graphql`
	query BlogPostByPath($path: String!){
		markdownRemark(frontmatter: { path: { eq: $path} }){
			html
			frontmatter{
				title
				path
				date
				keywords
			}
		}
	}
`